<template>
  <div class="admin-dashboard">
    <AdminHeader />
    <main>
      <b-container>
        <div class="mt-5">
          <b-card>
            <b-card-body class="p-0">
              <b-card-title>
                <b-row>
                  <b-col>Non-Eligible List</b-col>
                  <b-col class="text-right">
                    <b-button variant="primary" @click="addNew">Add Member</b-button>
                  </b-col>
                </b-row>
              </b-card-title>
              <b-alert :show="!!errorMessage" variant="danger">{{
                      errorMessage
                    }}</b-alert>
              <b-table striped responsive ref="table" stacked="lg" :fields="fields" :items="items">
                <template #cell(id)="data">                  
                  <b-button class="btn btn-info" @click="edit(data.item)">Edit</b-button>
                </template>
              </b-table>
            </b-card-body>
          </b-card>
        </div>
      </b-container>
    </main>
    <b-modal id="modal-exclude-record" :title="modalTitle" size="lg" hide-footer>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <b-form-group label="Name">
            <validation-provider name="Name" rules="required" v-slot="validationContext">
              <b-form-input id="input-name" v-model="form.name" :state="getValidationState(validationContext)"
                placeholder="Enter name"></b-form-input>
              <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Entity ID">
            <validation-provider name="EntityId" rules="required|integer" v-slot="validationContext">
              <b-form-input id="input-entityId" v-model="form.entityId" :state="getValidationState(validationContext)"
                placeholder="Enter entity id"></b-form-input>
              <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Ineligibility Type">
            <validation-provider name="Ineligibility Type" rules="required" v-slot="validationContext">
              <b-form-select :state="getValidationState(validationContext)" id="select-positionType"
                v-model="form.ineligibilityType" :options="typeOptions"></b-form-select>
              <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>

          <b-row class="mt-3">
            <b-col class="text-left">              
            </b-col>
            <b-col class="text-right">
              <b-button v-if="!isNew" type="button" variant="danger" @click="onDelete">Delete</b-button>
              <b-button type="submit" variant="primary" class="ml-3">Save</b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import AdminHeader from "@/components/admin/Header.vue";

export default {
  name: "ManageUsers",
  components: {
    AdminHeader,
  },
  data() {
    return {
      isSaving: false,
      isNew: false,
      modalTitle: "Add New",
      form: {
        id: 0,
        name: "",
        ineligibilityType: "",
        entityId: ""
      },
      rawItems: [],
      fields: [
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Entity ID",
          key: "entityId",
        },
        {
          label: "Type",
          key: "ineligibilityTypeText",
        },
        {
          label: "",
          key: "id",
        },
      ],
      loading: false,
      errorMessage: "",
      types: [{
        key: 1,
        value: "Cannot Endorse Officer, Can Endorse Board"
      },
      {
        key: 2,
        value: "Cannot Endorse Board, Can Endorse Officer"
      },
      {
        key: 3,
        value: "Cannot Endorse Both"
      }
      ],
      typeOptions: [
        { value: null, text: 'Please select an option' },
        { value: '1', text: 'Cannot Endorse Officer, Can Endorse Board' },
        { value: '2', text: 'Cannot Endorse Board, Can Endorse Officer' },
        { value: '3', text: 'Cannot Endorse Both' },
      ]
    };
  },
  computed: {
    items() {
      return this.rawItems.map(x => {
        return {
          name: x.name,
          ineligibilityType: this.types.find(t => t.key === x.ineligibilityType).key,
          ineligibilityTypeText: this.types.find(t => t.key === x.ineligibilityType).value,
          id: x.id,
          entityId: x.entityId
        }
      })
    }
  },
  async mounted() {
    let response = await this.$store.dispatch("settings/getExcludedList");
    console.log(response.data)
    this.rawItems = response.data;
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    addNew() {
      this.reset();
      this.isNew = true;
      this.modalTitle = "Add New";
      this.$bvModal.show("modal-exclude-record");
    },
    edit(item) {
      console.log('item', item)
      this.modalTitle = "Edit";
      this.isNew = false;
      this.$bvModal.show("modal-exclude-record");
      this.form.name = item.name;
      this.form.entityId = item.entityId;
      this.form.id = item.id;
      this.form.ineligibilityType = item.ineligibilityType;
    },
    reset() {
      this.form.name = "";
      this.form.entityId = "";
      this.form.id = 0;
      this.form.ineligibilityType = "";
    },
    async onDelete(){
      await this.$store.dispatch(
        "settings/deleteExcludedList",
        this.form.id
      );
      const response = await this.$store.dispatch("settings/getExcludedList");
      this.rawItems = response.data;
      this.$bvModal.hide("modal-exclude-record");
    },
    async onSubmit() {
      console.log('form', this.form)
      await this.$store.dispatch("settings/saveExcludedList", this.form);
      const response = await this.$store.dispatch("settings/getExcludedList");
      this.rawItems = response.data;
      this.$bvModal.hide("modal-exclude-record");
    },
  },
};
</script>
